import React from 'react'

const BottomNav = () => {
  return (
    <div style={{
        width:' 92%',
  background: '#0E111B',
  border:' 2px solid #21273a',
  borderRadius: '15px',
  minHeight:'2rem',
  display: 'flex',
  flexDirection: 'row',
justifyContent: 'space-evenly',
alignItems: 'center',
  paddingLeft: '30px',
  paddingRight: '30px',
  margin:' 1rem auto',
  color:'rgb(185, 179, 179)',
  fontSize:'0.9rem'
    }}>
<div>Swap</div>
<div>Tokens</div>
<div>...</div>
    
    </div>
  )
}

export default BottomNav