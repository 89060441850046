import React from 'react'
import Hero from '../components/hero/Hero'
import FeaturesSection from '../components/FeaturesSection'
import Community from '../components/Community'
import About from '../components/About'
import Footer from '../components/Footer'
const Home = () => {
  return (
    <div>
         <Hero/>
      <FeaturesSection />
      <Community  />
      <About />
      <Footer />
    </div>
  )
}

export default Home