import React, { useState, useEffect } from 'react';
import img from '../assets/cybor.png'
const Community = () => {


  return (
    <div className='community-container'>
        <h1>Join the Web3 Innovation Collective</h1>
   <h2>Darkset is at the forefront of the Web3 revolution, uniting blockchain and cybersecurity enthusiasts. We inspire developers to pioneer advanced solutions, championing user autonomy over digital identities, assets, and experiences</h2>

   <h3>Darkset Community</h3>
   <p>Venture into the digital frontier with us. At the nexus of blockchain and cybersecurity, we're shaping the web3 universe. </p>
   <p>As guardians of the virtual cosmos, our community is dedicated to pushing boundaries and redefining the impossible. Join our voyage, and together, let's architect the next digital epoch.</p>
   <img src={img}/>
    </div>
  )
}

export default Community