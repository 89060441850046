import React from 'react'
import img from '../../assets/darkset.png'
import Nav from '../Nav'
import { Link } from 'react-router-dom'
const Hero = () => {

  return (
    <div className='hero-container'>
      <Nav />
        <img src={img} alt='darkset protocol' className='hero-img'/>
        <div className='hero-text'>
          <h1 >Darkset</h1>
          <h2 style={{color:'white', background:'transparent',marginBottom:'1rem'}} >Protocol</h2>
          <p style={{maxWidth:'280px'}}> Setting the Standard for Advanced Security! Unleashing Encrypted Communication, Seamless Data Transfer, and Ironclad Payment Security. </p>
          <div style={{display:'flex',flexDirection:'column',marginTop:'2rem'}}>
            {/* <button onClick={handleScroll} style={{width:'11rem',height:'3.2rem',borderRadius:'20px',background:'#000119',border:'none',color:'white',fontWeight:'bold',marginBottom:'1rem',boxShadow:'box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>Read More</button> */}
            <Link to={'/app'}>
            <button style={{width:'11rem',height:'3.2rem',borderRadius:'20px',background:'linear-gradient(57.75deg, #8f0194 14.44%, #2C63FF 85.65%)',border:'none',color:'white',fontWeight:'bold',boxShadow:'0 16px 24px rgb(247 15 255 / 48%)'}}>Launch App</button>

            </Link>
          
          </div>
        </div>

    </div>
  )
}

export default Hero