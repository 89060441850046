import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import DappPage from "./pages/DappPage";

function App() {
  return (
    <div className="App">
    <div>
    <Routes>
    <Route path="/" element={   <Home />} />
    <Route path="/app" element={   <DappPage />} />
    </Routes>

    </div>
    </div>
  );
}

export default App;
