import React from 'react'
import logo from '../assets/logoweb.png'
const Footer = () => {
  return (
    <footer className="footer">
    <div className="container">
    <div className="footer-logo">
          <img src={logo} alt="Darkset Logo" />
        </div>
      <div className="footer-content">
     
        <div className="footer-links">
  <div className="footer-column">
    <h4>Explore</h4>
    <ul>
      <li><a href="#about">Development</a></li>
      <li><a href="#features">Documentation</a></li>
      <li><a href="#contact">Security</a></li>
    </ul>
  </div>
  <div className="footer-column">
    <h4>Community</h4>
    <ul className="social-icons">
      <li>
        <a href="https://twitter.com/DarksetProtocol" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter"></i> Twitter
        </a>
      </li>
      <li>
        <a href="https://t.me/DarksetProtocol" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-telegram"></i> Telegram
        </a>
      </li>
    </ul>
  </div>
</div>

      </div>
      <div className="footer-bottom">
        <p>&copy; <span id="current-year"></span> Darkset Protocol. All rights reserved.</p>
      </div>
    </div>
  </footer>
  
  )
}

export default Footer