import React from "react";
import Logo from "../assets/logoweb.png";
import Eth from "../assets/eth.svg";
import { Link } from "react-router-dom";

function DappHeader(props) {
  const {address, isConnected, connect} = props;

  return (
    <header>
      <div className="leftH">
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <div className="rightH">
        <div className="connectButton" onClick={connect}>
          {isConnected ? (address.slice(0,4) +"..." +address.slice(38)) : "Connect"}
        </div>
      </div>
    </header>
  );
}

export default DappHeader;