import React from 'react'
import logo from '../assets/logoweb.png'
const Nav = () => {
  return (
    <div className='nav'>
        <img src={logo} alt='logo' className='logo'/>
 
    </div>
  )
}

export default Nav