import React from 'react'
import { Shield, SwapHoriz, CreditCard } from '@mui/icons-material';
import img from '../assets/slider-img.png'
import logo from '../assets/logoweb.png'
const FeaturesSection = () => {
  return (
    <section className="features">
    <div className="container">
    <div className='features-hero'>
   
    <h2>The Secure Asset $DARK Token</h2>

      <p>Darkset's token is your fortress of financial security. Safeguard your assets with confidence, knowing that your wealth is protected and readily available whenever you need it.</p>
      <img src={img} alt='crypto token' className='secure-image' style={{width:'240px'}}/>
    </div>
  
      <div className="feature" style={{marginTop:'3rem'}}>
        <Shield sx={{ fontSize: 40 }} />
        <h3>Secure Communication</h3>
        <p>Darkset Protocol stands as the vanguard of secure communication. Empowering a New Era of Secure Communication for Governments, Military, Enterprises, and Individuals.</p>
      </div>
      <div className="feature">
        <SwapHoriz sx={{ fontSize: 40 }} />
        <h3>Data Transfer</h3>
        <p>We are proud to offer a cutting-edge, 100% secure vault for safeguarding your valuable assets. Additionally, we enable the seamless and encrypted transfer of all data types, making us your trusted source for the most secure asset storage and data transmission.</p>
      </div>
      <div className="feature">
        <CreditCard sx={{ fontSize: 40 }} />
        <h3>Payments</h3>
        <p>Revolutionizing Business Payments! Unlock the Power of Cryptocurrency. Darkset Protocol is reshaping the landscape of business payments. We provide companies with the tools and knowledge to seamlessly accept cryptocurrency payments, offering the same convenience as traditional banking. </p>
      </div>
      <div className="feature">
        <img src={logo} alt='darkset logo' />
        <h3>Darkset Token $DARK</h3>
        <p>The Digital Currency for a Secure Future! As the cornerstone of our ecosystem, Darkset Token opens doors to a more secure and efficient future. Whether you're an investor, tech enthusiast, or blockchain advocate, Darkset Token represents a revolution in security, offering a multitude of opportunities and possibilities</p>
      </div>
    </div>
  </section>
  )
}

export default FeaturesSection