import React from 'react';
import { Twitter, Telegram } from '@mui/icons-material';

const About = () => {
  return (
    <section className="about-us">
    <div className="container">
      <div className="about-text">
        <h2>About Us</h2>
        <p>We are Darkset Protocol, a trailblazing force in the world of blockchain security. Our mission is to revolutionize digital protection, offering secure communication, data transfer, and payment solutions for the blockchain.</p>
      </div>
      <div className="social-links">
        <h2>Join Our Community</h2>
        <p>Connect with us on social media for the latest updates and discussions:</p>
        <div className="icons">
          <a href="https://twitter.com/DarksetProtocol" target="_blank" rel="noopener noreferrer">
            <Twitter sx={{ fontSize: 36, color: '#00a3e0' }} />
          </a>
          <a href="https://t.me/DarksetProtocol" target="_blank" rel="noopener noreferrer">
            <Telegram sx={{ fontSize: 36, color: '#00a3e0' }} />
          </a>
        </div>
      </div>
    </div>
  </section>
  )
}

export default About